<template>
  <!--
    Calendar for Deliveries
  -->
  <div class="deliveryCalendar">
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
      locale="de"
    >
      <template v-slot:eventContent="arg">        
        <div
          v-if="arg.event.id == 'null'"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
        <div
          v-else
          v-tooltip="arg.event.title"
          class="fc-event-main-frame"
        >
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">
              {{ arg.event.title }}
            </div>
          </div>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';

export default {
  name: "DeliveryCalendar",
  components: {
    FullCalendar,
  },
  mixins: [
    dateTimeMixin,
    urlParseMixin
  ],
  props: {
     excludedTypes: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        firstDay: 1,
        locale: "en",
        editable: false,
        selectable: false,
        selectMirror: true,
        weekNumbers: true,
        datesSet: this.handleDatesSet,
        eventClick: this.handleEventClick,
        events: this.filteredDeliveries,
        weekText: 'KW',
        eventDidMount: this.eventMounted,
        dayMaxEvents: 5,
        height: 'auto'
      },
      filteredDeliveries: [],
      deliveries: [],
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
  },
  watch: {
    "$i18n.locale" () {
      this.calendarOptions.locale = this.$i18n.locale;
    },
    excludedTypes () {
      this.filteredDeliveries = this.deliveries.filter(x => !this.excludedTypes.some(e => e.name == x.type) || x.type == 'Holiday');
      this.calendarOptions.events = this.filteredDeliveries;
    },
  },
  created () {
    if (this.$i18n.locale == "de") {
      this.calendarOptions.locale = deLocale.code;
    }
  },
  methods: {
    handleDatesSet (dateInfo) {
      if(!this.startDate) {
        this.startDate = dateInfo.start;
        this.endDate = dateInfo.end;
        this.getCalendarData();
      }
      else {
        let utcDateInfoStart = this.toMoment(dateInfo.start).utc();
        let utcDateStart = this.toMoment(this.startDate).utc();
        if(utcDateInfoStart.format() != utcDateStart.format()) {
          this.startDate = dateInfo.start;
          this.endDate = dateInfo.end;
          this.getCalendarData();
        }
      }
    },
    handleEventClick (clickInfo) {
      if(clickInfo.event.id != 'null') {
        const link = document.createElement('a');
        link.href = this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ clickInfo.event.id }`);
        link.target = '_blank';
        link.click();
      }
    },
    getCalendarData () {
      this.$emit("setLoading", true);
      this.axios
        .get(`/Issue/GetDelivery?start=${this.dateTime_isoString(this.startDate)}&end=${this.dateTime_isoString(this.endDate)}`)
        .then(response => {
          if(response && response.data) {
            this.filteredDeliveries = [];
            this.deliveries = [];
            response.data.forEach(el => {
                this.deliveries.push({
                  id: el.id ? el.id : null,
                  title: el.text,
                  start: el.startDate,
                  end: el.endDate,
                  type: el.type,
                  color: el.color,
                  allDay: true,
                });
            });

            this.filteredDeliveries = this.deliveries.filter(x => !this.excludedTypes.some(e => e.name == x.type) || x.type == 'Holiday');
            this.calendarOptions.events = this.filteredDeliveries;
          }
        })
        .finally(() => { this.$emit("setLoading", false); });
    }
  }
};
</script>

<style>
.fc-h-event .fc-event-main  {
  cursor: pointer;
}
</style>